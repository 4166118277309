import React from "react";
import { Link, navigate } from "gatsby";

const tabs = [
  { name: 'NSW' },
  { name: 'VIC' },
  { name: 'QLD' },
  { name: 'SA'  },
  { name: 'NT'  },
  { name: 'WA', },
  { name: 'TAS' },
];

const TabBar = ({ current = 'NSW' }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-highlight focus:border-highlight border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => (tab.name === current)).name}
          onBlur={event => {
            event.preventDefault();
            navigate(`/locations/${event.target.value}`)
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name.toLowerCase()} value={tab.name.toLowerCase()}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex justify-center">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              to={`/locations/${tab.name.toLowerCase()}`}
              key={tab.name}
              className={classNames(
                tab.name === current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800',
                'px-3 py-2 font-medium text-sm rounded-md'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
      <div className="sm:flex justify-center mt-6">
        <a href="/locations" className="mt-3 md:mt-0 md:ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-highlight hover:bg-highlight-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight">
          Search for a location near you
        </a>
      </div>
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default TabBar;
